<template>
  <div>
    <v-card outlined>
      <v-layout wrap justify-center pa-2>
        <v-flex xs12 sm3 align-self-center>
          <v-avatar color="#FFF9C4" size="80">
            <v-img
              v-if="storage.user.photo"
              :src="mediaURL + storage.user.photo"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="#FDCE48"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <span v-else class="itemKey">No Image</span>
          </v-avatar>
        </v-flex>
        <v-flex xs12 sm9 align-self-center pl-2>
          <v-layout wrap justify-start>
            <v-flex xs12 text-left>
              <span class="itemKey">Username</span>
              <span class="itemValue pl-2">
                {{ storage.user.username }}
                <span v-if="!storage.user.username">-</span>
              </span>
            </v-flex>
            <v-flex xs12 text-left>
              <span class="itemKey">Phone</span>
              <span class="itemValue pl-2">
                {{ storage.user.phone }}
                <span v-if="!storage.user.phone">-</span>
              </span>
            </v-flex>
            <v-flex xs12 text-left>
              <span class="itemKey">Email</span>
              <span class="itemValue pl-2">
                {{ storage.user.email }}
                <span v-if="!storage.user.email">-</span>
              </span>
            </v-flex>
            <v-flex xs12 text-left>
              <span class="itemKey">Quantity</span>
              <span class="itemValue pl-2">
                {{ storage.amount }}
              </span>
            </v-flex>
            <v-flex xs12 text-left>
              <span class="itemKey">Price</span>
              <span class="itemValue pl-2">
                {{ storage.price }}
                <span v-if="!storage.price">-</span>
              </span>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
  </div>
</template>
<script>
export default {
  props: ["storage"],
  data() {
    return {};
  },
  methods: {
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      day = day < 10 ? "0" + day : day;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>