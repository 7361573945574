<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDCE48"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FDCE48" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <span class="itemHeading"> Inventory </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start py-4>
      <v-flex xs12 sm12 md6 xl4 pa-1 v-for="(item, i) in inventory" :key="i">
        <InventoryItem v-bind:storage="item" @stepper="winStepper" />
      </v-flex>
      <v-flex xs12 v-if="inventory.length < 1">
        <span class="itemKey"> No data available </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center v-if="pages > 1">
      <v-flex xs12>
        <v-pagination
          v-model="currentPage"
          :length="pages"
          :total-visible="7"
          color="#919191"
        ></v-pagination>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import InventoryItem from "./inventoryItem";
export default {
  components: {
    InventoryItem,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      inventory: [],
      pages: 0,
      count: 20,
      currentPage: this.$route.query.page ? Number(this.$route.query.page) : 1,
      userid: this.$route.query.user,
    };
  },
  watch: {
    currentPage() {
      this.$router.push({
        path: "/Admin/Inventory",
        query: {
          page: this.currentPage,
          user: this.$route.query.user,
        },
      });
    },
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/inventory/admingetlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: this.$route.query.page,
          count: this.count,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.inventory = response.data.data.inventory;
            this.pages = response.data.pages;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    winStepper(windowData) {
      if (windowData.pageResponse.status) {
        this.msg = "Hive Deleted Successfully";
        this.showSnackBar = true;
        if (windowData.getData) this.getData();
      } else {
        this.msg = "Hive not Deleted";
        this.showSnackBar = true;
      }
    },
  },
};
</script>